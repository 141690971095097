const DateTime = date => {
  let x = new Date(date).valueOf();
  return x;
};

const getColor = status => {
  const colors = { todo: '#CCCCCC', done: '#66CCCC', proc: '#99CCFF', delay: '#FFC000' };
  return colors[status];
};

const dateFormat = Highcharts.dateFormat,
  defined = Highcharts.defined,
  isObject = Highcharts.isObject;

export const renderHighCharts = data => {
  let chartData = data.map(v => {
    let obj = {
      name: v.name,
      id: v.id,
      parent: v.parent
    };
    if (v.dependency) {
      obj.dependency = v.dependency;
    }
    if (v.status) {
      obj.color = getColor(v.status);
    }
    if (v.start) {
      obj.start = DateTime(v.start);
    }
    if (v.end) {
      obj.end = DateTime(v.end);
    }
    if (v.completed) {
      obj.completed = {
        amount: v.completed.amount,
        fill: getColor(v.completed.status)
      };
    }
    return obj;
  });
  Highcharts.ganttChart('container', {
    series: [
      {
        name: '',
        data: chartData
      }
    ],
    tooltip: {
      pointFormatter: function () {
        var point = this,
          format = '%Y/%m/%d',
          options = point.options,
          completed = options.completed,
          amount = isObject(completed) ? completed.amount : completed,
          status = (amount || 0) * 100 + '%',
          lines;

        lines = [
          {
            value: point.name,
            style: 'font-familiy: Microsoft YaHei;font-size: 1.5em;'
          },
          {
            title: '开始',
            value: dateFormat(format, point.start)
          },
          {
            visible: !options.milestone,
            title: '结束',
            value: dateFormat(format, point.end)
          },
          {
            title: '进度',
            value: status
          }
        ];

        return lines.reduce(function (str, line) {
          var s = '',
            style = defined(line.style) ? line.style : 'font-size: 1.5em;';
          if (line.visible !== false) {
            s = '<span style="' + style + '">' + (defined(line.title) ? line.title + ': ' : '') + (defined(line.value) ? line.value : '') + '</span><br/>';
          }
          return str + s;
        }, '');
      }
    },
    title: {
      text: '光大银行信用卡新一代综合业务管理系统建设项目',
      style: {
        fontSize: '40px'
      }
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25
      },
      xAxis: {
        dateTimeLabelFormats: {
          day: '%Y/%m/%d',
          week: '%Y/%m/%d',
          month: '%Y/%m',
          year: '%Y'
        }
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: []
      },
      format: '%Y/%m/%d'
    },
    scrollbar: {
      enabled: true
    },
    rangeSelector: {
      enabled: false,
      selected: 0
    },
    xAxis: {
      currentDateIndicator: true
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '20px'
        }
      }
    }
  });
};
