


















import { Vue, Component } from 'vue-property-decorator';
import { renderHighCharts } from './highcharts.js';
@Component({
  components: {}
})
export default class Home extends Vue {
  jsonVisible: boolean = false;
  json = [
    {
      name: '卡中心一期工作项',
      id: 'phase_one',
      status: 'proc'
    },
    {
      name: '授权',
      id: 'auth',
      parent: 'phase_one',
      completed: {
        amount: 0.45,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求梳理与分析',
      id: 'auth_sor',
      parent: 'auth',
      start: '2021-10-1',
      end: '2021-12-31',
      completed: {
        amount: 1,
        status: 'done'
      }
    },
    {
      name: '主体功能实现',
      id: 'auth_main',
      parent: 'auth',
      dependency: 'auth_sor',
      start: '2022-1-1',
      end: '2022-3-18',
      completed: {
        amount: 0.8,
        status: 'delay'
      },
      status: 'proc'
    },
    {
      name: '非主体功能实现',
      id: 'auth_addition',
      parent: 'auth',
      dependency: 'auth_sor',
      start: '2022-2-28',
      end: '2022-4-30',
      completed: {
        amount: 0.1,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求跟版追版',
      id: 'auth_new',
      parent: 'auth',
      dependency: 'auth_addition',
      start: '2022-5-9',
      end: '2022-8-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '额度',
      id: 'limit',
      parent: 'phase_one',
      completed: {
        amount: 0.5,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求梳理与分析',
      id: 'limit_sor',
      parent: 'limit',
      start: '2021-10-1',
      end: '2021-12-31',
      completed: {
        amount: 1,
        status: 'done'
      }
    },
    {
      name: '主体功能实现',
      id: 'limit_main',
      parent: 'limit',
      dependency: 'auth_sor',
      start: '2022-1-1',
      end: '2022-3-18',
      completed: {
        amount: 1,
        status: 'done'
      }
    },
    {
      name: '非金融功能实现',
      id: 'limit_addition',
      parent: 'limit',
      dependency: 'limit_sor',
      start: '2022-2-28',
      end: '2022-4-30',
      completed: {
        amount: 0.1,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求跟版追版',
      id: 'limit_new',
      parent: 'limit',
      dependency: 'limit_addition',
      start: '2022-5-9',
      end: '2022-8-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '分期',
      id: 'instm',
      parent: 'phase_one',
      completed: {
        amount: 0.5,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求梳理与分析',
      id: 'instm_sor',
      parent: 'instm',
      start: '2021-10-1',
      end: '2021-12-31',
      completed: {
        amount: 1,
        status: 'done'
      }
    },
    {
      name: '主体功能实现',
      id: 'instm_main',
      parent: 'instm',
      dependency: 'instm_sor',
      start: '2022-1-1',
      end: '2022-3-18',
      completed: {
        amount: 1,
        status: 'done'
      }
    },
    {
      name: '非主体功能实现',
      id: 'instm_addition',
      parent: 'instm',
      dependency: 'instm_sor',
      start: '2022-2-28',
      end: '2022-4-30',
      completed: {
        amount: 0.1,
        status: 'done'
      },
      status: 'proc'
    },
    {
      name: '需求跟版追版',
      id: 'instm_new',
      parent: 'instm',
      dependency: 'instm_addition',
      start: '2022-5-9',
      end: '2022-8-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '测试',
      id: 'test',
      parent: 'phase_one',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '用户故事测试',
      id: 'test_user_story',
      parent: 'test',
      start: '2022-2-28',
      end: '2022-3-18',
      completed: {
        amount: 0.1,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'SIT（第一轮）',
      id: 'test_sit1',
      parent: 'test',
      dependency: 'test_user_story',
      start: '2022-3-21',
      end: '2022-4-1',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'SIT（第二轮）',
      id: 'test_sit2',
      parent: 'test',
      dependency: 'test_sit1',
      start: '2022-4-4',
      end: '2022-4-15',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'SIT（第三轮）',
      id: 'test_sit3',
      parent: 'test',
      dependency: 'test_sit2',
      start: '2022-4-18',
      end: '2022-4-29',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'UAT（第一轮）',
      id: 'test_uat1',
      parent: 'test',
      dependency: 'test_sit3',
      start: '2022-5-1',
      end: '2022-5-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'UAT（第二轮）',
      id: 'test_uat2',
      parent: 'test',
      dependency: 'test_uat1',
      start: '2022-6-1',
      end: '2022-6-30',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'UAT（第三轮）',
      id: 'test_uat3',
      parent: 'test',
      dependency: 'test_uat2',
      start: '2022-7-1',
      end: '2022-7-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: 'UAT（第四轮）',
      id: 'test_uat4',
      parent: 'test',
      dependency: 'test_uat3',
      start: '2022-8-1',
      end: '2022-8-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '非功能测试（第一轮）',
      id: 'test_nft1',
      parent: 'test',
      dependency: 'test_uat2',
      start: '2022-5-1',
      end: '2022-6-30',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    },
    {
      name: '非功能测试（第二轮）',
      id: 'test_nft2',
      parent: 'test',
      dependency: 'test_nft1',
      start: '2022-7-1',
      end: '2022-8-31',
      completed: {
        amount: 0,
        status: 'done'
      },
      status: 'todo'
    }
  ];

  get editorHeight() {
    return window.innerHeight - 240 + '';
  }

  get jsonString() {
    return JSON.stringify(this.json, null, 2);
  }

  set jsonString(v) {
    this.json = JSON.parse(v);
  }

  confirm() {
    this.jsonVisible = false;
    localStorage.setItem('ganttChart', this.jsonString);
    renderHighCharts(this.json);
  }

  mounted() {
    let cacheData = localStorage.getItem('ganttChart');
    if (cacheData) {
      this.json = JSON.parse(cacheData);
    }
    renderHighCharts(this.json);
  }
}
